











// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import * as _ from "lodash";
import { EditorState } from "@codemirror/state"
import { EditorView, keymap } from "@codemirror/view"
import { defaultKeymap } from "@codemirror/commands"
import { sql, SQLDialect } from '@codemirror/lang-sql';
import { basicSetup } from "codemirror";
import { HighlightStyle, syntaxHighlighting, syntaxTree } from "@codemirror/language"
import { linter, Diagnostic, lintGutter } from "@codemirror/lint"
import { Tag, tags as t } from '@lezer/highlight';
import { autocompletion, CompletionContext } from '@codemirror/autocomplete';
export default Vue.extend({
  name: 'TriggerEditor',
  props: {
    template: {
      type: String,
      default: null
    },
    triggerVarMap: {
      type: Object,
      default() { return {} }
    },
    insertFromNavigator: {
      type: Object,
      default() {
        return {
          value: '',
          now: Date.now()
      }}
    },
    superGlobals: {
    type: Array
  },
  },
  data() {
    return {
      keywords:[ "and", "or", "like", "not", "order", "by", "limit", "desc", "asc"],
      propCompletions:{
          campaign : ["name", "id", "state", "dailyBudget", "budget", "cpm", "spend", "tapAvgCPA", "totalAvgCPA", "cpt", "impressions", "taps", "tapInstalls", "viewInstalls", "totalInstalls", "ttr", "tapInstallRate", "totalInstallRate", "dateCreated", "goalEventCount", "goalRevenue", "goalRevenuePer", "goalCostPer", "goalROAS", "goalARPU", "goalAMPU"],
          adgroup : ["name", "id", "state", "spend", "tapAvgCPA", "totalAvgCPA", "cpt", "cpm", "impressions", "taps", "tapInstalls", "viewInstalls", "totalInstalls", "ttr", "tapInstallRate", "totalInstallRate", "dateCreated", "campaignId", "goalEventCount", "goalRevenue", "goalRevenuePer", "goalCostPer", "goalROAS", "goalARPU", "goalAMPU"],
          keyword: ["name", "id", "state", "bidAmount", "spend", "tapAvgCPA", "totalAvgCPA", "cpt", "cpm", "impressions", "taps", "tapInstalls", "viewInstalls", "totalInstalls", "ttr", "tapInstallRate", "totalInstallRate", "dateCreated", "adGroupId", "campaignId", "goalEventCount", "goalRevenue", "goalRevenuePer", "goalCostPer", "goalROAS", "goalARPU", "goalAMPU", "matchType"],
        },
      validTrigger: false,
      allNodeIds : [],
      allIdentifiers : [],
      allTypes : [],
      allOperators: [],
      allComposites: [],
      allStatments: [],
      startState : null,
      view: null,
      endNodes : [3,4,5,8], //string,number,bool,')'
      relationalOperators: ['>', '<', '!=', '=', '<=', '>=', '<>', '!' ,'like', 'not like', '+', '-', '*', '/', '%'],
      ignoreNodes : [25,31,27],
      comments: []
    }
  },
  watch: {
    template: function (value) {
      if(value) {
        this.view.dispatch({
          changes: { from: 0, to: this.view.state.doc.length, insert: value }
        })
      }
    },
    insertFromNavigator: function(value) {
      this.view.dispatch({
        changes: { from: this.view.state.selection.main.head, to: this.view.state.selection.main.head, insert: value.value }
      })
  },
  superGlobals: {
    deep: true,
    handler (value, oldValue) {
      if (JSON.stringify(value.sort()) != JSON.stringify(oldValue.sort())) {
      this.watchSuperGlobals()
      }
    }  
  }
  },
  mounted() {

    // THEME
  const settings = {
    background: '#FFFFFF',
    foreground: '#000000',
    caret: '#000000',
    selection: '#FFFD0054',
    gutterBackground: '#FFFFFF',
    gutterForeground: '#00000070',
    lineHighlight: '#00000008',
  }

  const actionEditorTheme = EditorView.theme({
    '&': {
      backgroundColor: settings.background,
      color: settings.foreground,
    },
    ".cm-scroller": { overflow: "auto" },
    '.cm-content': {
      caretColor: settings.caret,
    },
    '.cm-cursor, .cm-dropCursor': {
      borderLeftColor: settings.caret,
    },
    '&.cm-focused .cm-selectionBackgroundm .cm-selectionBackground, .cm-content ::selection':
    {
      backgroundColor: settings.selection,
    },
    '.cm-activeLine': {
      backgroundColor: settings.lineHighlight,
    },
    '.cm-gutters': {
      backgroundColor: settings.gutterBackground,
      color: settings.gutterForeground,
    },
    '.cm-activeLineGutter': {
      backgroundColor: settings.lineHighlight,
    },
  });

  const custom = {
    identifier: Tag.define()
  }

  // Highlight
  const myHighlightStyle = HighlightStyle.define([
    {
      tag: t.comment,
      color: '#CFCFCF',
    },
    {
      tag: [t.number, t.bool, t.null],
      color: '#E66C29',
    },
    {
      tag: t.keyword,
      color: '#2EB43B',
    },
    {
      tag: t.operator,
      color: '#4EA44E',
    },
    {
      tag: t.name,
      color: '#925A47',
    },
    {
      tag: t.string,
      color: '#704D3D',
    },
  ])

    let timeout;

    const inputListener = EditorState.transactionExtender.of(() => {
      if(timeout) return;

      timeout = setTimeout(() => {
        this.$emit('valid', { isValid: this.validTrigger, code: this.view.state.sliceDoc(), comments: this.comments });
        timeout = null;
      }, 500);

      return null;
    });

    const sqlLinter = linter(view => {
      this.allNodeIds = []
      this.allIdentifiers = []
      this.allTypes = []
      this.allOperators = []
      this.allStatments = []
      this.allComposites = []
      let diagnostics: Diagnostic[] = []
      let index = 0
      this.validTrigger = true
      this.comments = []

      syntaxTree(view.state).cursor().iterate(node => {
        index++
        const item = _.cloneDeep(node);
        const lines = this.view.state.doc.text
        const fullText = this.view.state.doc.text.toString()

        if(item.type.id === 1){
          this.comments.push({
            from: item.from,
            to: item.to,
            type: 'LineComment'
          })
        }

        if(item.type.id === 2){
          this.comments.push({
            from: item.from,
            to: item.to,
            type: 'BlockComment'
          })
        }

        if(!this.ignoreNodes.includes(item.type.id)){
          this.allNodeIds.push(node.type.id)
        }

        if(item.type.id === 25){
          const identifier = fullText.slice(item.from, item.to)
        }

        if(item.type.id === 31){
          const identifier = fullText.slice(item.from, item.to)
          this.allStatments.push(identifier)
        }

        if(item.type.id === 27){
          const identifier = fullText.slice(item.from, item.to)
          this.allComposites.push(identifier)
        }

        if(!fullText){
          this.validTrigger = false
          diagnostics.push({
            from: 0,
            to: 0,
            severity: "error",
            message: "Query cannot be empty",
            source: "Basic validator",
          })
        }

        //deal identifiers
        if(item.type.id === 18) {
          const currentIdentifier = fullText.slice(item.from, item.to)
          if(currentIdentifier && ['curdate','current_date'].includes(currentIdentifier.toLowerCase())) return
          this.allIdentifiers.push(currentIdentifier)

          const prevIdentifier = this.allIdentifiers[this.allIdentifiers.length - 2]
          const prevNode= this.allNodeIds[this.allNodeIds.length - 2]
          const prevToPrevNode= this.allNodeIds[this.allNodeIds.length - 3]

          if(prevToPrevNode === 18 && prevNode === 14){
            // console.log('prop')
            let validProp = true
            switch (prevIdentifier) {
              case 'campaign':
                validProp = this.triggerVarMap.campaignProps.includes(currentIdentifier)
                break;
              case 'adgroup':
                validProp = this.triggerVarMap.adgroupProps.includes(currentIdentifier)
                break;
              case 'keyword':
                validProp = this.triggerVarMap.keywordProps.includes(currentIdentifier)
                break;
              default:
                break;
            }
            if(!validProp){
              this.validTrigger = false
              diagnostics.push({
                from: item.from,
                to: item.to,
                severity: "error",
                message: `Invalid prop "${currentIdentifier}" on "${prevIdentifier}" ASA field`,
                source: "ASA prop validator",
              })
            }
          }
          else if(!this.triggerVarMap.asaFields.includes(currentIdentifier)){
            this.validTrigger = false
            diagnostics.push({
              from: item.from,
              to: item.to,
              severity: "error",
              message: `Invalid ASA field "${currentIdentifier}"`,
              source: "ASA field validator",
            })
          }else if(this.triggerVarMap.asaFields.includes(currentIdentifier) && item.to === fullText.length){
            // let transaction = view.state.update({changes: {from: item.to, insert: '.'}})
            // view.dispatch(transaction)
            // view.dispatch({selection: {anchor: node.to+1}})
          }
        }
        if(item.type.id === 17){
          const identifier = fullText.slice(item.from, item.to)
          this.allComposites.push(identifier)
          const prevIdentifier = this.allIdentifiers[this.allIdentifiers.length - 2]
          const prevNode= this.allNodeIds[this.allNodeIds.length - 2]
          if(!this.superGlobals.includes(identifier)){
            this.validTrigger = false
            diagnostics.push({
              from: item.from,
              to: item.to,
              severity: "error",
              message: `Invalid Global variable "${identifier}"`,
              source: "Global variable validator",
            })
          }
          else if(prevNode != 15){
            this.validTrigger = false
            diagnostics.push({
              from: item.from,
              to: item.to,
              severity: "error",
              message: `Global variable "${identifier}" is only allowed after an operator`,
              source: "Global variable validator",
            })
          }
        }
        //deal operators
        if(node.type.id === 15) {
          const operator = fullText.slice(item.from, item.to)
          this.allOperators.push(operator)
          if(!this.relationalOperators.includes(operator)){
            this.validTrigger = false
            diagnostics.push({
              from: item.from,
              to: item.to,
              severity: "warning",
              message: "Invalid operator. Query might fail",
              source: "SQL validator",
            })
          }
          if(this.allNodeIds[this.allNodeIds.length-2] === 15){ //previous is an operator as well, then make sure there is no space between them
            const prevOp = this.allOperators[this.allOperators.length - 2]
            // this.allOperators.pop()
            // this.allOperators.pop()
            // this.allOperators.push(prevOp+operator)
            // this.allNodeIds.pop()
            this.validTrigger = false
            diagnostics.push({
              from: item.from - 2,
              to: item.to,
              severity: "error",
              message: "No space allowed between operators",
              source: "SQL validator",
            })
          }
        }

        //deal error
        if (["⚠"].includes(node.name)) {
          this.validTrigger = false
          diagnostics.push({
            from: node.from,
            to: node.to,
            severity: "error",
            message: "Invalid or incomplete condition",
            source: "Basic validator",
          })
        }
      })
      // if (!this.endNodes.includes(this.allNodeIds[this.allNodeIds.length - 1])) {
      //   const currentEndNode = this.allNodeIds[this.allNodeIds.length - 1]
      //   this.validTrigger = false
      //   diagnostics.push({
      //     from: this.view.state.doc.text.toString().length,
      //     to: this.view.state.doc.text.toString().length + 1,
      //     severity: "error",
      //     message: "Expected token"
      //   })
      // }

      const wholeText = this.view.state.doc.text.toString();

      const wholeTextWithoutBraces = wholeText.replaceAll(/\(|\)/ig,'')

      const individualTriggers = wholeTextWithoutBraces.split(/ and | or | AND | OR /)

      const relevantNodes = this.allNodeIds.filter(n => ![7,8,9,10,11,12,20,28,29].includes(n))

      // if(relevantNodes.length % 5 !== 0){
      //   this.validTrigger = false
      //   diagnostics.push({
      //     from: this.view.state.doc.text.toString().length,
      //     to: this.view.state.doc.text.toString().length + 1,
      //     severity: "error",
      //     message: `Incomplete or invalid statement`
      //   })
      // }

      let set = []
      // const setCount = Math.floor(relevantNodes.length/5) || 1
      relevantNodes.forEach((n,i) => {
        set.push(n)
        if(set.length === 5 || (i > 4 && i === (relevantNodes.length -1))){

          const setAsString = set.join().replaceAll(',','')

          // if(!/181421154|181421153/.test(setAsString)){
          //   const docLen = this.view.state.doc.text.toString().length
          //   // const errTriggerAt = docLen/setCount
          //   this.validTrigger = false
          //   diagnostics.push({
          //     from: docLen,
          //     to: docLen + 1,
          //     severity: "error",
          //     message: `Unable to parse the query. Please make sure it follows pattern "ASAField.ASAProp Comparator Value" ex. "campaign.installs > 10", "adgroup.state > 'active'""`
          //   })
          //   return
          // }
          set = []
        }
      })
      return diagnostics
    })

    const CustomDialect = /*@__PURE__*/SQLDialect.define({
      keywords: "and or like not order by limit desc asc",
      operatorChars: "+-*/<>=!",
      // builtin: "",
      // types: "name id state dailybudget budget cpm spend cpa cpt impressions taps installs ttr cr bid datecreated",
      types: "curdate() current_date()",
      specialVar:"@",
      doubleQuotedStrings: true,
    });

    this.startState = EditorState.create({
      doc: "",
      extensions: [
        basicSetup,
        sql({
          schema: {
            campaign: this.triggerVarMap.campaignProps, //tables and their columns
            adgroup: this.triggerVarMap.adgroupProps,
            keyword: this.triggerVarMap.keywordProps,
          },
          // upperCaseKeywords: true,
          dialect: CustomDialect,
        }),
        keymap.of(defaultKeymap),
        actionEditorTheme,
        syntaxHighlighting(myHighlightStyle),
        lintGutter(),
        inputListener,
        sqlLinter,
        autocompletion({
          override:[
            (context: CompletionContext) => {
              return this.completionFromList(context, this.superGlobals, 'variable');
            },
            (context: CompletionContext) => {
              return this.completionFromList(context, ['campaign', 'adgroup', 'keyword','curdate()', 'current_date()'], 'type');
            },
            (context: CompletionContext) => {
              return this.completionFromList(context, this.keywords, 'keyword');
            },
            (context: CompletionContext) => {
              return this.completionFromList(context, ["true", "false", "null", "unknown"], 'variable');
            },
            (context: CompletionContext) => {
              const completePropertyAfter = ["PropertyName", "."];
              let nodeBefore = syntaxTree(context.state).resolveInner(context.pos, -1);
              if (completePropertyAfter.includes(nodeBefore.name) &&
                nodeBefore.prevSibling?.name == "Identifier") {
                let object = nodeBefore.prevSibling
                if (object?.name == "Identifier") {
                  let from = /\./.test(nodeBefore.name) ? nodeBefore.to : nodeBefore.from
                  let variableName = context.state.sliceDoc(object.from, object.to) as any;

                  if ((this.propCompletions as any)[variableName])
                    return this.completeProperties(from, (this.propCompletions as any)[variableName], true)
                }
              }
              return null
            }
          ]
        })
      ]
    })

    this.view = new EditorView({
      state: this.startState,
      parent: this.$refs.codem as Element
    })

    if(this.template) {
      this.view.dispatch({
        changes: { from: 0, to: this.view.state.doc.length, insert: this.template }
      });
    }
  },
  methods:{
    watchSuperGlobals(){
        const nodes = []
        syntaxTree(this.view.state).cursor().iterate(node => {
          nodes.push(_.cloneDeep(node));
        });
        let globals = {};
        let rerun = false
        // Object.assign(globals, [...this.globalVars].reduce((a, v) => ({ ...a, [v]: 'writable' }), {}));
        // Object.assign(globals, [...this.scriptedArVars, ...this.miscVars].reduce((a, v) => ({ ...a, [v]: 'readonly' }), {}));
        // Object.assign(globals, this.scriptedArFunctions.map(fn => fn.split('(')[0]).reduce((a, v) => ({ ...a, [v]: 'readonly' }), {}));
        // let globalKeys = Object.keys(globals)
        for(let i=0; i<nodes.length; i++){
          // console.log(112, nodes[i].name, this.view.state.sliceDoc(nodes[i].from, nodes[i].to, nodes[i] ))
          if(nodes[i].name === "SpecialVar" && this.view.state.sliceDoc(nodes[i].from, nodes[i].to )!=''){
            if(!this.superGlobals.includes(this.view.state.sliceDoc(nodes[i].from, nodes[i].to ))){
              // console.log("removing "+ this.view.state.sliceDoc(nodes[i].from, nodes[i].to ))
              this.view.dispatch({
                changes: { from: nodes[i].from, to: nodes[i].to, insert:'' }
              })
              rerun = true
              break;
            }
          }
        }
        if(rerun == true){
          this.watchSuperGlobals()
        }     
    },
    completeProperties(from: number, object: any, isArray?: boolean) {
    let options = []
    if (isArray) {
      for (let name of object) {
        options.push({
          label: name,
          type: typeof object[name] == "function" ? "function" : "property"
        })
      }
    } else {
      for (let name in object) {
        options.push({
          label: name,
          type: typeof object[name] == "function" ? "function" : "property"
        })
      }
    }
    return {
      from,
      options,
      validFor: /^[\w$]*$/
    }
  },
    toSet(chars) {
    let flat = Object.keys(chars).join("");
    let words = /\w/.test(flat);
    if (words)
      flat = flat.replace(/\w/g, "");
    return `[${words ? "\\w" : ""}${flat.replace(/[^\w\s]/g, "\\$&")}]`;
  },
    prefixMatch(options) {
    let first = Object.create(null), rest = Object.create(null);
    for (let { label } of options) {
      first[label[0]] = true;
      for (let i = 1; i < label.length; i++)
        rest[label[i]] = true;
    }
    let source = this.toSet(first) + this.toSet(rest) + "*$";
    return [new RegExp("^" + source), new RegExp(source)];
  },
    completionFromList(context, list: string[], type: string) {
    const options = list.map(p => Object.assign({ label: p, type }));
    const [validFor, match] = options.every(o => /^\w+$/.test(o.label)) ? [/\w*$/, /\w+$/] : this.prefixMatch(options);
    const token = context.matchBefore(match);
    return token || context.explicit ? { from: token ? token.from : context.pos, options, validFor } : null;
  },
  }
})
